import {useTranslation} from "react-i18next";
import {useCallback, useState} from "react";

export function Header() {

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const {t} = useTranslation();
    const parentPage = 'http://deeptalk.tech';

    const showMenuMobile = () => {
        setShowMobileMenu(!showMobileMenu);
    }

    const closeMobileMenu = useCallback(() => {
        setShowMobileMenu(false)
    }, [])

    return (
        <header className="header">
            <div className="header__content">
                <a className="header__backlink" href={parentPage}>
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="20"
                            cy="20"
                            r="20"
                            fill="#74D414"
                        />
                        <path
                            d="M32.5722 31.3935L31.9412 30.3337C27.0934 22.1882 20.4584 22.6879 18.8344
                                22.9514V29.371L8.23529 18.753L18.8344 8.23529V14.555C18.8885 14.555 18.9422
                                14.5546 18.9963 14.5546C24.8864 14.5546 27.6049 17.0317 27.7216 17.1408C34.2065
                                22.5359 32.8221 30.1069 32.8075 30.1827L32.5722 31.3935ZM20.2372 21.9247C22.966
                                21.9247 27.9917 22.8296 31.9981 28.675C32.0584 26.3824 31.5765 21.5717 27.0995
                                17.8462L27.0985 17.8453C27.0217 17.7768 24.2731 15.3231 18.3784 15.5051L17.8937
                                15.5192V10.4954L9.56795 18.7558L17.8933 27.0962V22.1957L18.2509 22.1072C18.2763
                                22.1006 19.0335 21.9247 20.2372 21.9247Z"
                            fill="white"/>
                        <path
                            d="M20.2372 21.9247C22.966 21.9247 27.9917 22.8296 31.9981 28.675C32.0584 26.3824 31.5765
                                21.5717 27.0995 17.8462L27.0985 17.8453C27.0217 17.7768 24.2731 15.3231 18.3784
                                15.5051L17.8937 15.5192V10.4954L9.56795 18.7558L17.8933 27.0962V22.1957L18.2509
                                22.1072C18.2763 22.1006 19.0335 21.9247 20.2372 21.9247Z"
                            fill="white"/>
                    </svg>
                    Come back
                </a>
                <div className={showMobileMenu ? 'navbar__toggle active' : 'navbar__toggle'} onClick={showMenuMobile}></div>
                {/*<TopNavigation showMobileMenu={showMobileMenu} closeMobileMenuCB={closeMobileMenu ?? false}/>*/}
            </div>
        </header>
    );
}

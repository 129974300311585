import axios, {AxiosRequestConfig} from 'axios';

export type recognitionMathStruct = {
    box: number[]
    id_svae: number
    text: {
        prob: number
        text: string
    }
}
const createRoute = () => `${window.location.protocol}//${window.location.hostname}/recognition-math/processing/math/byte_stream/`;
const createBody = (data: File) => data
const createCfg = (): AxiosRequestConfig => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'image/png'
        },
        timeout: 120000
    }
}
const RecognitionMathByteStream = async (data: File): Promise<recognitionMathStruct[] | null> => {
    const result = await axios.post(createRoute(), createBody(data), createCfg());
    return result.data
}

export {RecognitionMathByteStream}


import axios, {AxiosRequestConfig} from 'axios';
import {v4 as uuidv4} from "uuid";
import {dialogDataStruct} from "./Dialog";

export type aiInitStruct = {
    type: string
    data: dialogDataStruct[]
    qr: boolean
    user_id: string
}


const createRoute = () => `${window.location.protocol}//${window.location.hostname}/ai/predict`;
const createBody = (): string => JSON.stringify({user_id: 0, text: "initialization", finish: "false"});
const createCfg = (): AxiosRequestConfig => {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    }
}
const AiInit = async (): Promise<aiInitStruct | null> => {

    try {
        let result = await axios.post(createRoute(), createBody(), createCfg());

        if (result.data) {

            const _data: dialogDataStruct[] = []

            for (let i = 0; i < result.data.data.length; i++) {
                _data.push({
                    text: result.data.data[i],
                    image: result.data.img_id[i]
                })
            }

            return {
                type: result.data.type,
                data: _data,
                qr: result.data.qr,
                user_id: uuidv4(),
            }
        }

        return null
    } catch (err) {
        return null;
    }
}

export {AiInit}

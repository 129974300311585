import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import ReactHowler from 'react-howler';
import {API} from "../../api";

export type playerByStringDataStruct = {
    text: string
    image: number
}

export type statePlayer = "play" | "stop" | "pause"

type parameters = {
    data: playerByStringDataStruct[]
    state: statePlayer
    onEnd: any
    onChangeCurrentTrack: (currentTrack: playerByStringDataStruct) => void
    voice: string,
    defaultImage?: number
}

const parseStringToChunk = (data: string, image: number, length: number = 249): playerByStringDataStruct[] => {
    const chunk: playerByStringDataStruct[] = [];
    let tmpString = "";
    for (let item of data.toString().split(' ')) {
        if ((tmpString.length + `${item} `.length) > length) {
            chunk.push({
                text: tmpString,
                image: image
            })
            tmpString = "";
        }
        tmpString += `${item} `
    }
    chunk.push({
        text: tmpString,
        image: image
    })
    return chunk;
}

export const PlayerByString = (prop: parameters) => {

    const [chunk, setChunk] = useState<playerByStringDataStruct[]>([])
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [state, setState] = useState<statePlayer>("stop");
    const [currentTrack, setCurrentTrack] = useState<playerByStringDataStruct>();
    const [trackList, setTrackList] = useState<playerByStringDataStruct[]>([]);
    const [onEnd, setOnEnd] = useState<boolean>(false)

    const playerRef = useRef<any>()

    //Изменение входящего состояния
    useEffect(() => {
        setState(prop.state)
    }, [prop.state])

    //Изменилось состояние плеера
    useEffect(() => {
        switch (state) {
            case "play":
                if (playerRef.current) {
                    playerRef.current.play()
                    setIsPlaying(true)
                } else {
                    console.log("PlayerByString set state play", "но воспроизвести нечего")
                }
                break
            case "pause":
                if (playerRef.current) {
                    playerRef.current.pause()
                    setIsPlaying(false)
                }
                break
            case "stop":
                console.log("PlayerByString stop")
                if (playerRef.current) {

                    playerRef.current.stop()
                    setIsPlaying(false)
                    setCurrentTrack({text: "", image: 0})
                    setChunk([])
                }
                break
        }
    }, [state])

    //Появился текст для воспроизведения
    useEffect(() => {
        if (!prop.data.length) {
            console.warn("Нельзя передавать пустую строку для воспроизведения озвучивания")
        } else {
            const parseString: playerByStringDataStruct[] = []
            for (const item of prop.data) {
                for (const value of parseStringToChunk(item.text, item.image)) {
                    parseString.push(value)
                }
            }

            setChunk(parseString)
        }
    }, [prop.data])

    //Появился массив строк для озвучивания
    useEffect(() => {
        console.log("chunk", chunk)
        if (chunk.length) {
            convertTextToSound().then()
        }
    }, [chunk])

    //Изменилась текущая дорожка воспроизведения
    useEffect(() => {
        //Если дорожка установлена и текущее состояние play воспроизводим ее
        if (currentTrack?.text !== "" && state === "play") {
            setIsPlaying(true)
        }
        if (currentTrack) {
            prop.onChangeCurrentTrack(currentTrack)
        }
    }, [currentTrack])

    //Изменился список всех аудио файлов
    useEffect(() => {
        console.log("trackList", trackList)
        //Если в списке есть элементы и текущая дорожка не установлена, ставим первую дорожку в очередь
        if (trackList.length && (!currentTrack || currentTrack.text === "")) {
            setCurrentTrack(trackList.shift())
        }
    }, [trackList])

    //Изменилось состояния окончания дорожки
    useEffect(() => {
        //Если сигнал об окончании дорожки истина
        if (onEnd) {
            //Ставим сигнал об окончание в ложь
            setOnEnd(false)
            //Если в списке всех дорожек есть еще записи
            if (trackList.length) {
                //Воспроизводим первую дорожку из списка
                setCurrentTrack(trackList.shift())
            } else {
                //Ставим пустую дорожку
                setCurrentTrack({text: "", image: 0})
                //Вызываем функцию обратного вызова с сигналом окончания воспроизведения всех дорожек
                prop.onEnd()
            }
        }
    }, [onEnd])
    //Конвертируем массив строк для озвучивания в аудиофайлы
    const convertTextToSound = async () => {
        //Обновляем список всех дорожек
        setTrackList([])
        // const tracks: playerByStringDataStruct[] = []
        for (let item of chunk) {
            //Получаем новую аудио дорожку для каждой строки массива
            const tts = await API.Tts(item.text, prop.voice);
            //Если конвертация удалась и пришли данные, добавляем их в виде URL ссылки в очередь
            if (tts && tts.data) {
                // tracks.push({
                //     text: URL.createObjectURL(new Blob([tts.data], {type: 'audio/mpeg'})),
                //     image: item.image
                // })

                setTrackList((value: playerByStringDataStruct[]) => [...value, {
                    text: URL.createObjectURL(new Blob([tts.data], {type: 'audio/mpeg'})),
                    image: item.image
                }])
            } else {
                console.error("Не удалось преобразовать текст в звук", tts)
            }



        }
        // setTrackList((value: playerByStringDataStruct[]) => [...value, ...tracks])
    }

    return (
        <div>
            {
                currentTrack && currentTrack?.text !== "" &&
                <ReactHowler
                    src={currentTrack.text}
                    playing={isPlaying}
                    ref={playerRef}
                    onEnd={() => {
                        setOnEnd(true)
                    }}
                    format={["mp3"]}
                />
            }
        </div>
    );
};


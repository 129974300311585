import axios, {AxiosRequestConfig} from 'axios';

export type dialogDataStruct = {
    text: string
    image: number
}

type parameter = {
    userId: string,
    text: string,
    finish?: "false"
}

export type aiDialogStruct = {
    type: "question" | "result"
    qr: boolean
    data: dialogDataStruct[]
    result: {
        AI: any
        name: string
    },
    phone?: boolean
}

const createRoute = () => `${window.location.protocol}//${window.location.hostname}/ai/predict`;
const createBody = (data: parameter): string => JSON.stringify({user_id: data.userId, text: data.text, finish: "false"});
const createCfg = (): AxiosRequestConfig => {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    }
}

const AiDialog = async (prop: parameter): Promise<aiDialogStruct | null> => {
    try {
        const result = await axios.post(createRoute(), createBody(prop), createCfg());
        if (result.data) {

            const _data: dialogDataStruct[] = []

            for (let i = 0; i < result.data.data.length; i++) {
                _data.push({
                    text: result.data.data[i],
                    image: result.data.img_id[i]
                })
            }
            console.log("AiDialog -------", result)

            return {
                data: _data,
                qr: result.data.qr,
                // qr: true,
                type: result.data.type,
                phone: result.data.phone,
                result: result.data.result ? result.data.result: {},
                // phone: true
            }
        }
        return null
    } catch (err) {
        return null;
    }
}

export {AiDialog}

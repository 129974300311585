import axios, {AxiosRequestConfig} from 'axios';

const createRoute = () => `${window.location.protocol}//${window.location.hostname}/save-phone/`;
const createBody = (data: string): URLSearchParams => {
    const params = new URLSearchParams();
    params.append('phone', data);
    return params
}
const createCfg = (): AxiosRequestConfig => {
    return {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    }
}

const SavePhone = async (data: string): Promise<null> => {
    try {
        await axios.post(createRoute(), createBody(data), createCfg());
        return null
    } catch (err) {
        return null;
    }
}

export {SavePhone}

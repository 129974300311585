import {Tts} from "./converter/Tts";
import {SearchInText} from "./SearchInText";
import {AiInit} from "./ai/Init";
import {AiDialog} from "./ai/Dialog";
import {ImageCompression} from "./imageCompression";
import {GetPathToRecognizeMathFile} from "./recognition/getPathToRecognizeMathFile";
import {RecognitionMathByteStream} from "./recognition/recognitionMathByteStream";
import {GetResultMathAnalyze} from "./recognition/mathAnalyze";
import {StartRecognize} from "./converter/StartRecognize";
import {EndRecognize} from "./converter/EndRecognize";
import {SavePhone} from "./savePhone";
import {Translate} from "./converter/Translate";

export const API = {
    Tts,
    SearchInText,
    AiInit,
    AiDialog,
    ImageCompression,
    GetPathToRecognizeMathFile,
    RecognitionMathByteStream,
    GetResultMathAnalyze,
    StartRecognize,
    EndRecognize,
    SavePhone,
    Translate
}

import axios, {AxiosRequestConfig} from 'axios';

const createRoute = () => `${window.location.protocol}//${window.location.hostname}/compression/image/`;
const createBody = (data: File) => {
    const formData = new FormData
    formData.set("data", data)
    return formData
};
const createCfg = (): AxiosRequestConfig => {
    return {
        responseType: 'blob',
        headers: {
            "Content-Type": "image/jpeg",
        }
    }
}

const ImageCompression = async (data: File): Promise<Blob | null> => {
    try{
        const result = await axios.post(createRoute(), createBody(data), createCfg());
        return result.data
    }
    catch(err){
        return null;
    }
}
export {ImageCompression}

import React, {useEffect, useState} from "react";
import {resultMathAnalyzeStruct} from "../../api/recognition/mathAnalyze";

type parameter = {
    grade: resultMathAnalyzeStruct
}
const Recognize = (prop: parameter) => {

    const [gradeInfo, setGradeInfo] = useState<resultMathAnalyzeStruct>()
    const [imageRecognition, setImageRecognition] = useState<string>();
    const [errorRecognize, setErrorRecognize] = useState<string>();

    useEffect(() => {
        setGradeInfo(prop.grade)

        if (prop.grade.imageId === 0) {
            setErrorRecognize("You have a complicated photo, I haven't been able to recognize it yet. But I will definitely learn.")
        } else {
            setImageRecognition(`${window.location.protocol}//${window.location.hostname}/recognition-math/get_vis/${prop.grade.imageId}`)
        }
    }, [prop.grade])

    return (
        <>
            {
                gradeInfo &&
                <div style={{width: "100%"}}>
                    <hr className="item-recognize-dictation-hr"/>
                    <div className="item-recognize-dictation item-recognize-dictation-math">
                        <div style={{marginBottom: "1rem", display: "flex", justifyContent: "center"}}>
                            <strong style={{fontSize: "16px"}}>Solution evaluation</strong>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>
                                <div style={{marginBottom: "1rem"}}>
                                    <strong style={{fontSize: "16px"}}>The right decision</strong>
                                </div>
                                {
                                    gradeInfo?.referenceSolution.map((item, index) => {
                                        let className = "line";
                                        if (Array.isArray(gradeInfo.lineEmpty)) {
                                            className = gradeInfo.lineEmpty.includes(index) ? "empty-line line" : "line"
                                        }
                                        return (
                                            <div className={className} key={`reference-${index}`}>
                                                {item}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                <div style={{marginBottom: "1rem"}}>
                                    <strong style={{fontSize: "16px"}}>Your decision</strong>
                                </div>
                                {
                                    gradeInfo?.userSolution.map((item, index) => {
                                        let className = "line";
                                        if (Array.isArray(gradeInfo.lineError)) {
                                            className = gradeInfo.lineError.includes(index) ? "error-line line" : "line"
                                        }
                                        return (
                                            <div className={className} key={`user-${index}`}>
                                                {item}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/*<div style={{marginTop: "2rem"}}>*/}
                        {/*    <div style={{fontWeight: "700"}}>Ваш результат: {gradeInfo.gradeMessage}</div>*/}
                        {/*</div>*/}
                    </div>

                </div>
            }

            {
                imageRecognition &&
                <div style={{width: "100%"}}>
                    <hr className="item-recognize-dictation-hr"/>
                    <div className="item-recognize-dictation">
                        <div style={{marginBottom: "1rem"}}>
                            <strong style={{fontSize: "16px"}}>Recognized Solution</strong>
                        </div>
                        <a href={imageRecognition} target="_blank">
                            <img
                                src={imageRecognition}
                                alt=""
                                style={{maxWidth: "200px"}}
                            />
                        </a>
                    </div>
                </div>
            }
            {
                errorRecognize &&
                <div style={{width: "100%"}}>
                    <hr className="item-recognize-dictation-hr"/>
                    <div className="item-recognize-dictation">
                        <div style={{marginBottom: "1rem"}}>
                            <strong style={{fontSize: "16px"}}>Recognized Solution</strong>
                        </div>
                        <div style={{fontSize: "16px"}}>{errorRecognize}</div>
                    </div>
                </div>
            }
        </>
    )
}

export {Recognize}

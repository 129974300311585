import axios, {AxiosRequestConfig} from 'axios';

const createRoute = () => `${window.location.protocol}//${window.location.hostname}/converter-google/api/tts/google/`;
const createBody = (data: string): string => JSON.stringify({data: data});
const createCfg = (voice: string): AxiosRequestConfig => {
    return {
        responseType: 'arraybuffer',
        headers: {
            "Content-Type": "application/json",
            "Voice": voice,
        }
    }
}

const Tts = async (data: string, voice = 'en-US-Wavenet-F') => {
    try {
        return await axios.post(createRoute(), createBody(data), createCfg(voice));
    } catch (err) {
        return null;
    }
}

export {Tts}

import React, {useEffect, useState} from "react";
import RecordRTC from "recordrtc";
import {useParams} from "react-router-dom";
import {PlayerByString, playerByStringDataStruct, statePlayer} from "../../components/utils/PlayerByString";
import {API} from "../../api";
import {aiInitStruct} from "../../api/ai/Init";
import {aiDialogStruct, dialogDataStruct} from "../../api/ai/Dialog";
import {PlayBackgroundImage} from "../../components/utils/PlayBackgroundImage";
import {Qr} from "../../components/qr";
import {Recognize} from "../../components/recognize";
import {resultMathAnalyzeStruct} from "../../api/recognition/mathAnalyze";
import {Chat, questionAnswerItem} from "../../components/chat";
import {Timer} from "../../components/utils/timer";
import {InputPhone} from "../../components/elements/inputPhone";

const wsURL = `${window.location.protocol === "http:" ? "ws" : "wss"}://${window.location.hostname}/converter/google/ws/stt/`
// const wsURL = `ws://localhost:8080/ws/stt/google/`

const TIMER_SECOND: number = 3
const TIMER_SECOND_MATH: number = 300
type currentPlay = "dialog" | "question"

type queryParameters = {
    voice?: string
}

type currentState =
    "dialogInit" | "dialogPlay" | "dialogPause" | "dialogStop" | "dialogEnd" | "dialogQuestion"
    | "questionPlay" | "questionStop" | "questionEnd"


export type WSMessage = {
    topic: "ping" | "recognize"
    data?: number[]
}

export type WSResponse = {
    client: {
        ID: string
    }
    topic: "ping" | "partial" | "final" | "finalRefinement" | "recognize_math_start" | "recognize_math_end"
    error: {
        isError: boolean
        message: string
    }
    data: string
}

export const Lecture = () => {
    //Голос, который будет озвучивать
    const [voice, setVoice] = useState<string>("en-US-Wavenet-F")
    //Текст общения
    const [dialogItem, setDialogItem] = useState<dialogDataStruct[]>([])
    //Текст вопроса
    const [questionItem, setQuestionItem] = useState<dialogDataStruct[]>([])
    //Какой сейчас плеер работает
    const [currentPlayer, setCurrentPlayer] = useState<currentPlay>()
    //В каком сейчас состояние находится плеер
    const [currentState, setCurrentState] = useState<currentState>("dialogStop")
    //Клиент ВС
    const [wsClient, setWsClient] = useState<WebSocket>()
    //Пришло новое сообщение в ВС
    const [messageWS, setMessageWS] = useState<WSResponse>()
    //Отправлено новое сообщение в ВС
    const [sendMessageWS, setSendMessageWS] = useState<WSMessage>()
    //Состояние записи микрофона
    const [recorder, setRecorder] = useState<RecordRTC>()
    //Скрининговый поток записи микрофона
    const [recorderStream, setRecorderStream] = useState<MediaStream>()
    //Время, которое будет ожидаться ответ, после чего пойдем дальше (4 секунды)
    const [timerSecond, setTimerSecond] = useState<number>(TIMER_SECOND)
    //Состояние таймера ожидания ответа
    const [timerActive, setTimerActive] = useState<boolean>(false)
    //Время, которое будет ожидаться ответ по математике, после чего пойдем дальше (5 минут)
    const [timerSecondMath, setTimerSecondMath] = useState<number>(TIMER_SECOND_MATH)
    //Состояние таймера ожидания ответа по математике
    const [timerActiveMath, setTimerActiveMath] = useState<boolean>(false)
    //Список вопросов системы и ответов пользователя
    const [questionAnswerList, setQuestionAnswerList] = useState<questionAnswerItem[]>([])
    //Получаем входящие параметры из запроса
    const queryParams = useParams<queryParameters>()
    //Состояние плеера с диалогом
    const [dialogState, setDialogState] = useState<statePlayer>("stop")
    //Состояние плеера с ответом
    const [questionState, setQuestionState] = useState<statePlayer>("stop")
    //Уникальный идентификатор пользователя
    const [userId, setUserId] = useState<string>()
    //Фоновое изображение
    const [backgroundImage, setBackgroundImage] = useState<string>(`/img/dialog/1.jpeg`)
    //Уникальный идентификатор пользователя WS
    const [WSId, setWSId] = useState<string>()
    //Ответ от ai пришел с запросом показать qr
    const [qr, setQr] = useState<boolean>(false)
    //Показать блок с распознаванием
    const [showRecognize, setShowRecognize] = useState<boolean>(false)
    //Информация о решение задания
    const [gradeInfo, setGradeInfo] = useState<resultMathAnalyzeStruct>()
    //Запущена математика
    const [mathIsRun, setMathIsRun] = useState<boolean>(false)

    const [activeStart, setActiveStart] = useState<boolean>(true)

    const [activeStop, setActiveStop] = useState<boolean>(false)

    const [receivedResult, setReceivedResult] = useState<boolean>(false)

    const [audioContext, setAudioContext] = useState<AudioContext>()

    const [audioAnalyser, setAudioAnalyser] = useState<AnalyserNode>()

    const [mediaStreamSource, setMediaStreamSource] = useState<MediaStreamAudioSourceNode>()

    const [dialogInit, setDialogInit] = useState<boolean>(false)

    const [userName, setUserName] = useState<string>()

    const [stepDialog, setStepDialog] = useState<number>(0)

    const [needPhone, setNeedPhone] = useState<boolean>(false)

    const [userPhone, setUserPhone] = useState<string>()

    const [sendPhone, setSendPhone] = useState<boolean>(false)

    useEffect(() => {
        if (mediaStreamSource && audioAnalyser) {
            mediaStreamSource.connect(audioAnalyser)
        }
    }, [mediaStreamSource, audioAnalyser])

    useEffect(() => {
        if (audioAnalyser) {
            audioAnalyser.fftSize = 512;
            audioAnalyser.smoothingTimeConstant = 0.1;
        }
    }, [audioAnalyser])

    useEffect(() => {
        if (audioContext) {
            setAudioAnalyser(audioContext.createAnalyser())
            if (recorderStream) {
                setMediaStreamSource(audioContext.createMediaStreamSource(recorderStream))
            }
        }
    }, [audioContext])

    useEffect(() => {
        if (dialogItem.length) {
            setStepDialog(stepDialog + 1)

            let message: string = ""

            let step = 0
            for (const item of dialogItem) {
                message += ` ${item.text}`
                if (dialogItem.length < step) {
                    message += "."
                }
                step++
            }

            setQuestionAnswerList((value: questionAnswerItem[]) => [...value, {
                message: message,
                system: true
            }])
        }
    }, [dialogItem])

    //При изменении состояния показы блока с распознаванием меняем состояние математики
    useEffect(() => {
        setMathIsRun(showRecognize)
    }, [showRecognize])

    useEffect(() => {
        if (qr) {
            setTimerActiveMath(true)
            setTimerSecondMath(TIMER_SECOND_MATH)
        }
    }, [qr])

    //Обрабатываем входные параметры
    useEffect(() => {
        if (queryParams.voice) {
            setVoice(queryParams.voice)
        }
    }, [queryParams])

    //Получили информацию по решению пользователя
    useEffect(() => {
        if (gradeInfo) {
            let message

            if (gradeInfo.grade === 5) {
                message = "fabulous. I checked the solution and the answer. Everything is correct."
            } else {
                if (gradeInfo.referenceSolution[gradeInfo.referenceSolution.length - 1] === gradeInfo.userSolution[gradeInfo.userSolution.length - 1]) {
                    message = "your answer is correct. But I would solve this equation a little differently. Look."
                } else {
                    message = "well done. But this time the answer didn't work. See how I solve this equation."
                }
            }

            setQuestionItem([{
                text: message + " For this work, I used handwriting recognition technology. A\n" +
                    "more services for solving mathematical problems and equations. To continue communication, say\"continue\"",
                image: 0
            }])
            setCurrentState("questionPlay")

        }
    }, [gradeInfo])

    //Изменено состояние веб сокета
    useEffect(() => {
        if (wsClient) {
            wsClient.onopen = () => {
                console.log("Соединение с сервером по web socket установленно")
                setSendMessageWS({
                    topic: "ping"
                })
            }
            wsClient.onclose = (data: CloseEvent) => {
                console.warn("Соединение с сервером по web socket закрыто", data)
            }

            wsClient.onmessage = (data: MessageEvent) => {
                setMessageWS(JSON.parse(data.data))
            }
        } else {
            setWsClient(new WebSocket(wsURL))
        }
    }, [wsClient])

    //Изменился список вопросов и ответов
    useEffect(() => {
        //Проверяем что последний ответ дал пользователь и возобновляем лекцию
        if (questionAnswerList.length && !questionAnswerList[questionAnswerList.length - 1].system) {
            setCurrentState("dialogPlay")
        }
    }, [questionAnswerList])

    //Изменен скрининговый поток записи микрофона
    useEffect(() => {
        //Если поток еще не создан создаем его
        if (!recorderStream) {
            navigator.mediaDevices.getUserMedia({
                audio: true
            }).then(data => setRecorderStream(data))
        } else {
            initRecorder()
            setAudioContext(new AudioContext())
        }
    }, [recorderStream])

    useEffect(() => {
        if (recorder) {
            runCommandRecorder("start")
            setTimeout(() => {
                runCommandRecorder("pause")
            }, 1)
        }

    }, [recorder])

    //Текущее состояние лекции
    useEffect(() => {
        switch (currentState) {
            //Инициализируем новый диалог
            case "dialogInit":
                console.log("state dialogInit")
                setQuestionAnswerList([])
                setDialogItem([])
                setActiveStart(false)
                setActiveStop(true)
                setReceivedResult(false)
                runCommandRecorder("pause")
                setSendPhone(false)
                API.AiInit().then((data: aiInitStruct | null) => {
                    if (data !== null) {
                        setUserId(data.user_id)
                        setDialogItem(data.data)
                        setCurrentState("dialogPlay")
                        setDialogInit(true)
                    } else {
                        console.error("Ошибка при инициализации диалога")
                    }

                    // console.log("AiInit data", data)
                })
                break;
            //Диалог запущена - изменяем текущий плеер, запускаем плеер диалога, запускаем запись микрофона
            case "dialogPlay":
                console.log("state dialogPlay")
                // изменяем текущий плеер
                setCurrentPlayer("dialog")
                //запускаем плеер диалога
                setDialogState("play")
                runCommandRecorder("pause")
                //Очищаем QR
                setQr(false)
                //Скрываем распознавание
                setShowRecognize(false)
                //Удаляем решение
                setGradeInfo(undefined)

                setNeedPhone(false)
                break
            //Диалог остановлена - останавливаем плеер диалога, останавливаем запись микрофона, останавливаем плеер вопроса, обновляем состояние сетки
            case "dialogStop":
                console.log("state dialogStop")
                //останавливаем плеер диалога
                setDialogState("stop")
                setDialogItem([])
                //останавливаем запись микрофона
                runCommandRecorder("pause")
                //останавливаем плеер вопроса
                setQuestionState("stop")
                //обновляем состояние сетки
                setBackgroundImage(`/img/dialog/1.jpeg`)
                setActiveStart(true)
                setActiveStop(false)
                setDialogInit(false)
                setCurrentPlayer(undefined)

                setTimerActive(false)
                setTimerSecond(TIMER_SECOND)

                setTimerActiveMath(false)
                setTimerSecondMath(TIMER_SECOND_MATH)

                setStepDialog(0)
                setUserName(undefined)
                setNeedPhone(false)
                break
            //Диалог поставлен на паузу - TODO
            case "dialogPause":
                console.log("state dialogPause")
                setQuestionItem([])
                setDialogState("pause")
                runCommandRecorder("resume")
                console.log('123123212123123', recorder?.getState())

                break
            //Пользователь, что-то сказал в диалоге
            case "dialogQuestion":
                runCommandRecorder("resume")

                console.log("state dialogQuestion")
                //Если пришел qr после окончания воспроизведения запускаем тайме
                if (receivedResult) {
                    setNeedPhone(true)
                } else {
                    if (qr || needPhone) {
                        setDialogState("pause")
                        runCommandRecorder("pause")
                    } else {
                        setDialogState("pause")
                        setTimerSecond(TIMER_SECOND)
                        setTimerActive(true)
                    }
                }

                break
            //Диалог завершился полностью
            case "dialogEnd":
                console.log("state lectureEnd")
                //Останавливаем диалог
                setDialogState("stop")
                // Останавливаем вопрос
                setQuestionState("stop")
                //Очищаем диалог
                setDialogItem([])
                //Очищаем вопрос
                setQuestionItem([])
                //Останавливаем запись микрофона
                runCommandRecorder("pause")
                break
            //Запущен плеер с ответом
            case "questionPlay":
                console.log("state questionPlay")
                if (questionItem.length) {
                    setCurrentPlayer("question")
                    setQuestionState("play")
                    runCommandRecorder("pause")
                } else {
                    console.warn("Нельзя запустить плеер по тексту не передав значение")
                    setCurrentState("dialogPlay")
                }
                break
            //Плеер с ответом закончил работу
            case "questionEnd":
                console.log("state questionEnd")
                //TODO auto
                // runCommandRecorder("resume")
                if (!mathIsRun) {
                    setCurrentState("dialogPlay")
                } else {
                    runCommandRecorder("resume")
                }

                break
        }
    }, [currentState])
    //Попытка отправить новое сообщение по ВС
    useEffect(() => {
        //Если сообщение пришло, и вс открыт отправляем сообщение
        if (sendMessageWS && wsClient && wsClient.readyState === wsClient.OPEN) {
            wsClient.send(JSON.stringify(sendMessageWS))
        }
        //Ели сообщение пришло, но ВС не открыт нужно вывести ошибку TODO
        if (!wsClient && sendMessageWS) {
            console.warn("Получено новое сообщение но WebSocket, соединение еще не открыто")
        }
    }, [sendMessageWS])
    //Получено новое сообщение от ВС
    useEffect(() => {
        if (messageWS) {
            console.log(messageWS)
            //Перебираем типы сообщений
            switch (messageWS.topic) {
                case "partial":
                    //Если текущий плеер диалог и он запушен ставим его на паузу и начинаем записывать микрофон
                    /*         if (currentPlayer === "dialog" && currentState === "dialogPlay") {
                                 setCurrentState("dialogPause")
                             }*/
                    //Если текущий плеер диалог и идет запись микрофона, отключаем таймер так как пользователь, что-то сказал
                    if (currentPlayer === "dialog" && currentState === "dialogQuestion") {
                        setTimerActive(false)
                    }
                    break
                case "final":
                    //Если текущий плеер диалог и текущее состояние пауза нужно воспроизвести ответ на вопрос пользователя
                    if ((currentPlayer === "dialog" || !currentPlayer) && currentState === "dialogPause") {

                        API.SearchInText(messageWS.data)
                            .then(data => {
                                if (data && data.data) {
                                    setQuestionItem((value: dialogDataStruct[]) => [...value, {
                                        image: 0,
                                        text: data.data.data
                                    }])

                                } else {
                                    setQuestionItem((value: dialogDataStruct[]) => [...value, {
                                        image: 0,
                                        text: "Unfortunately, I don't know the answer to this question yet. But I remembered it and will definitely prepare for the next meeting."
                                    }])
                                }

                                setCurrentState("questionPlay")
                            })
                    }
                    //Если текущий плеер диалог и текущее состояние запись ответа на вопрос - нужно выполнить запрос к нейронной сети
                    if (currentPlayer === "dialog" && currentState === "dialogQuestion") {

                        // let message = messageWS.data
                        //
                        // if (stepDialog === 1) {
                        //     if (message.split(" ").length === 1) {
                        //         message = message[0].toUpperCase() + message.slice(1);
                        //     } else {
                        //         message = "";
                        //     }
                        //
                        // }

                        //Устанавливаем ответа пользователя на поставленный вопрос
                        setQuestionAnswerList((value: questionAnswerItem[]) => [...value, {
                            message: messageWS.data,
                            system: false
                        }])
                        getDataAI(messageWS.data)
                    }
                    //Если сейчас решение математики и плеер завершил озвучивать ответ нужно ожидать "продолжить"
                    if (mathIsRun && currentState === "questionEnd") {
                        if (messageWS.data.toLowerCase() === "continue") {
                            setTimerActiveMath(false)
                            setTimerSecondMath(TIMER_SECOND_MATH)
                            setCurrentState("dialogPlay")
                            getDataAI("awake")
                        }
                    }

                    break
                case "recognize_math_start":
                    setQr(false)
                    setQuestionAnswerList((value: questionAnswerItem[]) => [...value, {
                        message: "I have received your reply and am reviewing it.",
                        system: true
                    }])
                    console.log("recognize_math_start", messageWS)
                    break
                case "recognize_math_end":
                    setGradeInfo(JSON.parse(messageWS.data))
                    setQuestionAnswerList((value: questionAnswerItem[]) => [...value, {
                        message: "Response parsed and displayed",
                        system: true
                    }])
                    console.log("recognize_math_end", messageWS)
                    break
                case "ping":
                    setWSId(messageWS.client.ID)
                    console.log("ping data", messageWS)
                    break
                default:
                    break
            }
        }
    }, [messageWS])

    /*    useEffect(() => {
            if (timerSecond > 0 && timerActive) {
                setTimeout(setTimerSecond, 1000, timerSecond - 1)
            } else if (timerSecond === 0 && timerActive) {
                setQuestionAnswerList((value: questionAnswerItem[]) => [...value, {
                    message: "Вы не дали ответ",
                    system: false
                }])
                getDataAI("")
            } else {
                setTimerActive(false)
            }
        }, [timerSecond, timerActive])*/

    const runCommandRecorder = (state: "start" | "stop" | "pause" | "resume") => {
        switch (state) {
            case "start":
                recorder?.startRecording()
                break;
            case "pause":
                recorder?.pauseRecording()
                break;
            case "resume":
                recorder?.resumeRecording()
                break
            case "stop":
                recorder?.stopRecording()
                break;
            default:
                recorder?.stopRecording()
                break;
        }
    }

    const initRecorder = () => {
        if (recorderStream) {
            setRecorder(undefined)
            //Создаем запись микрофона
            setRecorder(new RecordRTC(recorderStream, {
                type: 'audio',
                mimeType: 'audio/wav',
                recorderType: RecordRTC.StereoAudioRecorder,
                desiredSampRate: 16000,
                timeSlice: 500,
                numberOfAudioChannels: 1,
                // disableLogs: true,
                ondataavailable: (blob: Blob) => {
                    blob.arrayBuffer().then((data) => {
                        setSendMessageWS({
                            topic: "recognize",
                            data: Array.from(new Uint8Array(data))
                        })
                    })
                }
            }))
        }
    }

    const getDataAI = (message: string) => {
        //Получаем данные из сетки для дальнейшего диалога
        API.AiDialog({
            userId: userId ?? "",
            finish: "false",
            text: message
        }).then((dialog: aiDialogStruct | null) => {

            if (dialog === null) {
                console.error("Ошибка получения информации по диалогу")
            } else {

                if (dialog.qr) {
                    console.log("Показываем qr и ставим все на паузу")
                    setQr(true)
                    setShowRecognize(true)
                    setDialogItem(dialog.data)
                } else {
                    if (dialog.type === "result") {
                        setReceivedResult(true)
                    }

                    console.log("AiDialog", dialog)
                    setDialogItem(dialog.data)
                }
            }
        })
    }

    const getStateLocales = () => {
        switch (currentState) {
            case "dialogInit":
                return "Starting a dialogue";
            case "dialogPlay":
                return "There is a dialogue";
            case "dialogPause":
                return "We listen to your question";
            case "dialogStop":
                return "Dialog stopped";
            case "dialogEnd":
                return "Dialogue completed";
            case "dialogQuestion":
                if (needPhone) {
                    return "Waiting for phone number";
                } else {
                    return "We are waiting for the answer to the question";
                }

            case "questionEnd":
                return "Finished answering the question";
            case "questionPlay":
                return "We answer the question";
            case "questionStop":
                return "Question answer stopped";
            default:
                return "unknown";
        }
    }

    useEffect(() => {
        if (sendPhone) {
            setUserPhone(undefined)
        }
    }, [sendPhone])

    return (
        <div style={{margin: "1rem", display: "flex", flexDirection: "column"}}>
            <div style={{textAlign: "end", fontSize: "20px", width: "100%", minHeight: "20px"}}>
                {getStateLocales()}
                {
                    timerActiveMath &&
                    <Timer
                        onEnd={() => {
                            setTimerActiveMath(false)
                            setTimerSecondMath(TIMER_SECOND_MATH)
                            setCurrentState("dialogPlay")
                            getDataAI("awake")
                        }}
                        active={timerActiveMath}
                        second={timerSecondMath}
                        visible={true}
                    />
                }

                {
                    timerActive &&
                    <Timer
                        onEnd={() => {
                            setTimerActive(false)
                            setTimerSecond(TIMER_SECOND)
                            setQuestionAnswerList((value: questionAnswerItem[]) => [...value, {
                                message: "You didn't answer",
                                system: false
                            }])
                            getDataAI("")
                        }}
                        active={timerActive}
                        second={timerSecond}
                        visible={true}
                    />
                }


            </div>
            <div style={{width: "100%", display: "flex"}}>
                <div style={{width: "70%"}}>
                    {
                        needPhone &&
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "250px"}}>
                            <div style={{marginBottom: "1rem"}}>
                                <strong style={{fontSize: "16px"}}>Fill in your phone number</strong>
                            </div>
                           <div style={{display: "flex", alignItems: "center"}}>
                               <InputPhone onChange={(phone: string) => {
                                   if (phone.length >= 11) {
                                       setUserPhone(phone)
                                   } else {
                                       setUserPhone(undefined)
                                   }

                                   // API.SavePhone(phone) setSendPhone
                               }}/>
                               {
                                   userPhone &&

                                   <button className="homeScreen__action" onClick={() => {
                                       API.SavePhone(userPhone)
                                       setCurrentState("dialogStop")
                                       // setSendPhone(true)
                                   }} style={{marginBottom: 0}}>
                                       <svg
                                           width="40"
                                           height="40"
                                           xmlns="http://www.w3.org/2000/svg"
                                           fill="none"
                                           viewBox="0 0 24 24"
                                           strokeWidth="1.5"
                                           stroke="currentColor"
                                           className="w-6 h-6">
                                           <path
                                               strokeLinecap="round"
                                               strokeLinejoin="round"
                                               d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                           />
                                       </svg>
                                   </button>

                               }
                           </div>
                            {
                                sendPhone &&
                                <div style={{marginTop: "1rem"}}>
                                    <strong style={{fontSize: "16px"}}>Successfully</strong>
                                </div>
                            }
                        </div>
                    }
                    {
                        dialogItem &&
                        <PlayerByString
                            data={dialogItem}
                            voice={voice}
                            state={dialogState}
                            onEnd={() => {
                                setCurrentState("dialogQuestion")
                                console.log("dialogItem on end")
                            }}
                            defaultImage={1}
                            onChangeCurrentTrack={(currentTrack: playerByStringDataStruct) => {
                                if (currentTrack.image) {
                                    setBackgroundImage(`/img/dialog/${currentTrack.image}.jpeg`)
                                }
                            }}
                        />
                    }

                    {
                        questionItem &&
                        <PlayerByString
                            data={questionItem}
                            voice={voice}
                            state={questionState}
                            onEnd={() => {
                                if (dialogInit) {
                                    setCurrentState("questionEnd")
                                } else {
                                    setCurrentState("dialogStop")
                                    setCurrentPlayer(undefined)
                                }

                            }}
                            onChangeCurrentTrack={() => {
                            }}
                        />
                    }

                    {
                        backgroundImage && backgroundImage !== "" && !qr && !showRecognize && !needPhone &&
                        <PlayBackgroundImage backgroundImage={backgroundImage}/>
                    }
                    {
                        showRecognize &&
                        <div style={{display: "flex", justifyContent: "space-between", columnGap: "3rem"}}>
                            <div style={{width: "100%", maxWidth: "45vh"}}>
                                <div style={{fontSize: "20px", display: 'flex', flexDirection: 'column'}}>
                                    <strong>
                                        Solve equation step by step
                                    </strong>
                                    <pre style={{
                                        fontSize: "26px",
                                        backgroundColor: "#d7d7d7",
                                        padding: "1rem",
                                        width: "100%",
                                        textAlign: "center"
                                    }}>
                                        4x+5*12=78-10
                                    </pre>
                                </div>
                                <div className="item-recognize-dictation item-recognize-dictation-math" style={{textAlign: "center"}}>
                                    <div style={{marginBottom: "1rem"}}>
                                        <strong style={{fontSize: "16px"}}>Solution example</strong>
                                    </div>
                                    <img src="/img/recognizer-maths-example-new.png" alt="" style={{maxWidth: "200px"}}/>
                                </div>
                            </div>
                            {gradeInfo &&
                                <Recognize grade={gradeInfo}/>
                            }
                            {
                                WSId && qr &&
                                <Qr show={qr} WSId={WSId}/>
                            }

                        </div>
                    }


                    <div style={{marginTop: "1rem", display: "flex", justifyContent: "center"}}>
                        <button
                            className="homeScreen__action"
                            onClick={() => {
                                if (activeStart) {
                                    setCurrentState("dialogInit");
                                }
                            }}
                            style={{display: "flex", flexDirection: "column", alignItems: "center"}}
                        >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 0}}>
                                <circle cx="20" cy="20" r="20" fill={activeStart ? "#74d414" : "#909090"}/>
                                <path d="M30 20L16 29.4L16 10.6L30 20Z" fill="white"/>
                            </svg>
                            <div style={{marginTop: ".5rem"}}>
                                Start
                            </div>
                        </button>
                        <button
                            className="homeScreen__action"
                            onClick={() => {
                                if (activeStop) {
                                    setCurrentState("dialogStop")
                                }
                            }}
                            style={{display: "flex", flexDirection: "column", alignItems: "center"}}
                        >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 0}}>
                                <circle cx="20" cy="20" r="20" fill={activeStop ? "#74d414" : "#909090"}/>
                                <rect x="12.5714" y="12.5714" width="14.8571" height="14.8571" fill="white"/>
                            </svg>
                            <div style={{marginTop: ".5rem"}}>
                                Stop
                            </div>
                        </button>
                        <button
                            className="homeScreen__action"
                            onClick={() => {
                                setCurrentState("dialogPause")

                                setActiveStop(true)
                            }}
                            style={{display: "flex", flexDirection: "column", alignItems: "center"}}
                        >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill={true ? "#74d414" : "#909090"}/>
                                <path
                                    d="M13.9917 31.1169C13.9764 30.2514 13.8458 29.3923 13.6028 28.5608C13.3652 27.7479 13.2447 26.9064 13.2447 26.0595L13.2448 14.9867C13.2447 14.7264 13.346 14.4819 13.5298 14.298C13.718 14.1102 13.9682 14.0089 14.2341 14.0131C14.7623 14.0214 15.192 14.4719 15.1921 15.0176L15.1923 19.6129C15.1923 19.7242 15.2374 19.8251 15.3103 19.898C15.3833 19.971 15.4841 20.0161 15.5954 20.0161C15.8181 20.0161 15.9986 19.8356 15.9985 19.6129L15.9985 10.1334C15.9985 9.81629 16.122 9.51824 16.3462 9.29408C16.5704 9.06989 16.8684 8.94639 17.1855 8.94643C17.5025 8.94639 17.8006 9.06989 18.0247 9.29404C18.2489 9.51819 18.3723 9.81629 18.3723 10.1333L18.3723 18.7868C18.3724 19.0095 18.5529 19.1899 18.7755 19.1899C18.9982 19.1899 19.1786 19.0094 19.1786 18.7868L19.1788 8.49479C19.1788 7.84028 19.7112 7.30781 20.3657 7.30785C21.0201 7.30781 21.5527 7.84032 21.5527 8.4948L21.5525 18.117C21.5524 18.2004 21.5778 18.278 21.6213 18.3424C21.6937 18.4497 21.8165 18.5203 21.9557 18.5203C22.1784 18.5203 22.3589 18.3399 22.3589 18.1172L22.3589 9.8612C22.3609 9.20852 22.8924 8.67812 23.5456 8.67812C23.8625 8.67807 24.1608 8.80153 24.3854 9.02564C24.6094 9.24966 24.7329 9.54784 24.7329 9.86493L24.7329 19.5757L24.7329 23.9055C24.7329 24.0168 24.778 24.1176 24.8509 24.1905C24.9239 24.2635 25.0247 24.3086 25.136 24.3086C25.3587 24.3086 25.5392 24.1281 25.5391 23.9055L25.5391 19.5757C25.5391 19.1929 25.6883 18.833 25.9589 18.5623C26.2296 18.2916 26.5896 18.1425 26.9724 18.1425C27.4171 18.1425 27.7788 18.5043 27.7788 18.9489L27.7788 24.1844C27.7788 25.7503 25.1543 29.5733 23.9949 31.1169L13.9917 31.1169Z"
                                    fill="white"/>
                            </svg>
                            <div style={{marginTop: ".5rem"}}>
                                Raise a hand
                            </div>
                        </button>
                    </div>
                </div>
                <div style={{width: "30%", marginLeft: "1rem"}}>
                    <div>
                        <div style={{fontSize: "20px", display: 'flex', flexDirection: 'column'}}>
                            <strong>
                                Communication history
                            </strong>
                        </div>
                        {<Chat questionAnswerList={questionAnswerList}/>}
                    </div>

                </div>
            </div>

        </div>
    )
}

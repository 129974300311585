import axios, {AxiosRequestConfig} from 'axios';

const createRoute = (data: string) => `${window.location.protocol}//${window.location.hostname}/recognition-math/get_vis/${data}`;
const createCfg = (): AxiosRequestConfig => {
    return {
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'image/png'
        }
    }
}

const GetPathToRecognizeMathFile = async (data: string) => {
    try {
        const result = await axios.get(createRoute(data), createCfg());
        return result.data
    } catch (err) {
        return null;
    }
}

export {GetPathToRecognizeMathFile}

import React, {useEffect, useRef} from "react";
import style from './style.module.css'

export type questionAnswerItem = {
    message: string,
    system: boolean
}

type parameter = {
    questionAnswerList: questionAnswerItem[]
}
const Chat = (prop: parameter) => {

    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight
            })
        }
    }, [prop.questionAnswerList])
    return (
        <div className={style.containerChat} ref={containerRef}>
            {
                prop.questionAnswerList.map((item: questionAnswerItem, index: number) => {
                    return (
                        <div key={index} className={item.system ? style.chatItemOther : style.chatItemMe}>
                                    <span className={style.chatItemText}>
                                        {item.message}
                                    </span>
                        </div>
                    )
                })
            }
        </div>
    )
}

export {Chat}

import axios, {AxiosRequestConfig} from 'axios';
import {resultMathAnalyzeStruct} from "../recognition/mathAnalyze";

export type endRecognizeRequestBody = {
    fileId: number,
    text: string[]
}

const createRoute = () => `${window.location.protocol}//${window.location.hostname}/converter-google/api/recognize_math_end/`;
const createBody = (data: resultMathAnalyzeStruct, WSID: string): FormData => {
    const formData = new FormData()
    formData.append("id", WSID)
    formData.append("data", JSON.stringify(data))
    return formData
};
const createCfg = (): AxiosRequestConfig => {
    return {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }
}

const EndRecognize = async (data: resultMathAnalyzeStruct, WSID: string) => {
    try {
        return await axios.post(createRoute(), createBody(data, WSID), createCfg());
    } catch (err) {
        return null;
    }
}

export {EndRecognize}

import axios, {AxiosRequestConfig} from 'axios';
import {endRecognizeRequestBody} from "../converter/EndRecognize";

export type resultMathAnalyzeStruct = {
    grade: number
    gradeMessage: string
    lineEmpty: number[]
    lineError: number[]
    referenceSolution: string[]
    userSolution: string[]
    imageId: number,
    error? : {
        type: string,
        message: any
    }
}

const createRoute = () => `${window.location.protocol}//${window.location.hostname}/analyze/math/`;
const createCfg = (): AxiosRequestConfig => {
    return {
        responseType: 'json',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'image/png'
        }
    }
}

const GetResultMathAnalyze = async (data: endRecognizeRequestBody): Promise<resultMathAnalyzeStruct | null> => {
    try {
        const result = await axios.post(createRoute(), {data: data.text.join("##")}, createCfg());
        result.data.lineEmpty = Array.isArray(result.data.lineEmpty) ? result.data.lineEmpty : []
        result.data.lineError = Array.isArray(result.data.lineError) ? result.data.lineError : []
        result.data.imageId = data.fileId
        return result.data
    } catch (err) {
        return null;
    }
}

export {GetResultMathAnalyze}

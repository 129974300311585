import axios from 'axios';
import {API} from "./index";

const createRoute = () => `${window.location.protocol}//${window.location.hostname}/search-in-text/`;
const createBody = (data: string) => ({
    data: data
});
const createCfg = () => {
    return {
        headers: {
            "Language": "ru"
        }
    }
}

const SearchInText = async (data: string) => {
    try {

        const translateDataEn = await API.Translate(data, "en-US", "ru")

        const searchInTextData = await axios.post(createRoute(), createBody(translateDataEn?.data.data), createCfg());

        const translateData = await API.Translate(searchInTextData.data.data)
        return translateData
    } catch (err) {
        console.error(err)
        return null;
    }
}

export {SearchInText}

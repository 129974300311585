type parameter = {
    backgroundImage: string
}

export const PlayBackgroundImage = (prop: parameter) => {
    return (
        <div style={{display: "flex", width: "100%", justifyContent: "center", marginTop: "1rem"}}>
            <img src={prop.backgroundImage} alt="" style={{width: "60%"}}/>
        </div>
    )
}

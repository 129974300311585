import {ChangeEvent, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {API} from "../../api";
import {endRecognizeRequestBody} from "../../api/converter/EndRecognize";
import {resultMathAnalyzeStruct} from "../../api/recognition/mathAnalyze";
import {recognitionMathStruct} from "../../api/recognition/recognitionMathByteStream";

export const Math = () => {

    const [gradeInfo, setGradeInfo] = useState<resultMathAnalyzeStruct>()
    const [WSID, setWSID] = useState<string>("")
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [recognizeError, setRecognizeError] = useState<string>()
    const queryParams = useParams()
    const refDiv = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (queryParams.WSID) {
            setWSID(queryParams.WSID)
        }
    }, [queryParams])

    useEffect(() => {
        if (gradeInfo) {
            if (recognizeError) {
                gradeInfo.error = {
                    type: "recognizeError",
                    message: recognizeError
                }
            }

            console.log("EndRecognize gradeInfo", gradeInfo)
            API.EndRecognize(gradeInfo, WSID).then()
        }
        console.log("gradeInfo", gradeInfo)
    }, [gradeInfo])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setShowSuccess(false)
        if (event.target.files && event.target.files.length) {
            API.StartRecognize(WSID).then(() => {
                setShowSuccess(true)
            })
            if (refDiv.current) {
                refDiv.current.dataset.text = event.target.files[0].name
            }
            runRecognition(event.target.files[0]).then()
        }
    }

    const runRecognition = async (file: File) => {

        //Сообщить о получение инфомрации

        let fileSend = file
        if ((file.size / 1024) > 300) {
            const compressionFile = await API.ImageCompression(file)
            if (compressionFile !== null) {
                fileSend = new File([compressionFile], "file.png", {type: compressionFile.type})
            }
        }

        API.RecognitionMathByteStream(fileSend)
            .then((data: recognitionMathStruct[] | null) => {

                if (data === null){
                    console.error("Ошибка при распознавание файла!!!", data)
                    return
                }

                const endRecognizeRequestBody: endRecognizeRequestBody = {
                    fileId: 0,
                    text: []
                }

                for (const item of data) {
                    endRecognizeRequestBody.fileId = item.id_svae
                    endRecognizeRequestBody.text.push(item.text.text)
                }

                console.log("recognitionMathByteStream", data)
                console.log("endRecognizeRequestBody", endRecognizeRequestBody)

                checkResult(endRecognizeRequestBody)
            })
            .catch(error => {
                const endRecognizeRequestBody: endRecognizeRequestBody = {
                    fileId: 0,
                    text: [
                        "",
                        "",
                        "",
                        "",
                        "",
                        ""
                    ]
                }
                setRecognizeError(error)
                checkResult(endRecognizeRequestBody)
                console.error("RecognitionError", error)
            })
            .finally(() => {
                console.log("start recognitionMathByteStream", Date())
                // setIsRunRecognition(false);
            })
    }

    const checkResult = (endRecognizeRequestBody: endRecognizeRequestBody) => {
        console.log("checkResult", endRecognizeRequestBody)
        API.GetResultMathAnalyze(endRecognizeRequestBody)
            .then((data: resultMathAnalyzeStruct | null) => {
                if (data === null) {
                    console.error("не удалось выполнить анализ!!!",data)
                    return
                }
                setGradeInfo(data)

                const referenceSolution = [];
                const userSolution = [];
                console.log("result data", data.referenceSolution)
                for (const key in data.referenceSolution) {
                    // @ts-ignore
                    referenceSolution.push(data.referenceSolution[key])
                }
                for (const key in data.userSolution) {
                    // @ts-ignore
                    userSolution.push(data.userSolution[key])
                }

                data.referenceSolution = referenceSolution
                data.userSolution = userSolution
                setGradeInfo(data)
            })
            .catch(error => {
                console.error("getResultMathAnalyze", error)
            })
    }

    return (
        <div style={{maxWidth: "320px", margin: "auto", marginTop: "2rem"}}>
            <div style={{fontSize: "20px", marginBottom: "1rem"}}>
                <strong>Upload your answer</strong>
            </div>
            <div ref={refDiv}  className="file-math-upload-wrapper" style={{}} data-text="Download the file">
                <input type="file" className="file-math-upload-wrapper" onChange={handleChange}/>
            </div>

            {
                showSuccess &&
                <div style={{fontSize: "20px", marginTop: "1rem"}}>
                    <p>I am reviewing your answer.</p>
                    <p>You can return to the lecture, in a couple of seconds I will show it.</p>
                </div>
            }
        </div>
    )
}

import React, {useEffect, useState} from "react";
import QRCode from "react-qr-code";

const TIMER_SECOND: number = 300

type parameter = {
    show: boolean
    WSId: string
}
const Qr = (prop: parameter) => {

    const [WSId, setWSId] = useState<string>()
    const [show, setShow] = useState<boolean>()
    const [timerActive, setTimerActive] = useState<boolean>(false)
    const [timerSecond, setTimerSecond] = useState<number>(TIMER_SECOND)
    const [qrValue, setQrValue] = useState<string>()

    useEffect(() => {
        setShow(prop.show)
    }, [prop.show])

    useEffect(() => {
        setWSId(prop.WSId)
    }, [prop.WSId])

    useEffect(() => {
        if (show) {
            setTimerSecond(TIMER_SECOND)
            setTimerActive(true)
        }
    }, [show])

    useEffect(() => {
        if (WSId) {
            console.log()
            setQrValue(`${window.location.href}math/${WSId}`)
        }
    }, [WSId])

    useEffect(() => {
        if (timerSecond > 0 && timerActive) {
            setTimeout(setTimerSecond, 1000, timerSecond - 1)
        } else if (timerSecond === 0 && timerActive) {
            //Что делать после окончания таймера?
        } else {
            setTimerActive(false)
        }
    }, [timerSecond, timerActive])

    return (
        <>
            {
                qrValue && show &&
                    <div style={{fontSize: "20px", display: 'flex', flexDirection: 'column', alignItems: "center"}}>
                        <strong style={{marginBottom: "1rem", fontSize: "1em"}}>
                            Scan the QR code or go to <a target="_blank" href={qrValue}>link</a>
                        </strong>
                    <QRCode  value={qrValue} level={"H"} fgColor={"#74D414"} size={128}/>
                </div>
            }
            </>
    )
}

export {Qr}

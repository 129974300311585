import React from "react";
import InputMask from "react-input-mask";

type parameter = {
    onChange: (phone: string) => void
}

const InputPhone = (prop: parameter) => {



    const changeInput = (event: any) => {
        let valid = event.target.value
        valid = valid.replace(/\+/g, "")
        valid = valid.replace(/ /g, "")
        valid = valid.replace(/_/g, "")
        prop.onChange(valid)
    }

    return (
        <InputMask
            style={{fontSize: "20px", padding: "1rem", borderRadius: "10px"}}
            mask="+999999999999999"
            onChange={changeInput}
            alwaysShowMask={true}
        />
    )
}

export {InputPhone}

import axios, {AxiosRequestConfig} from 'axios';

type bodyParameter = {
    text: string
    sourceLang: string
    targetLang: string
}

const createRoute = () => `${window.location.protocol}//${window.location.hostname}/converter-google/api/trl/google/`;
const createBody = (data: bodyParameter): string => JSON.stringify(data);
const createCfg = (): AxiosRequestConfig => {
    return {
        headers: {
            "Content-Type": "application/json"
        }
    }
}

const Translate = async (text: string, sourceLang = 'ru-RU', targetLang = "en") => {
    try {
        const data: bodyParameter = {
            text: text,
            sourceLang: sourceLang,
            targetLang: targetLang
        }
        return await axios.post(createRoute(), createBody(data), createCfg());
    } catch (err) {
        return null;
    }
}

export {Translate}

import './App.css';
import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Lecture} from "./pages/lecture";
import {Header} from "./layouts/default/Header";
import {Footer} from "./layouts/default/Footer";
import {Math} from "./pages/math";

const App = () => {
    return (
        <BrowserRouter>
            <Header/>
            <main>
                <Routes>
                    <Route path="/" element={<Lecture/>}>
                        <Route path=":voice" element={<Lecture/>}/>
                    </Route>
                    <Route path="/math/:WSID" element={<Math/>}></Route>
                </Routes>
            </main>
            <Footer/>
        </BrowserRouter>
    )
}


export default App;

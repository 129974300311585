import axios, {AxiosRequestConfig} from 'axios';

const createRoute = () => `${window.location.protocol}//${window.location.hostname}/converter-google/api/recognize_math_start/`;
const createBody = (data: string) => {
    const formData = new FormData();
    console.log("formData", data)
    formData.append("id", data)

    return formData
}
// const createBody = (data: string) => ({id: data})
const createCfg = (): AxiosRequestConfig => {
    return {

        headers: {
            "Content-Type": "multipart/form-data",
        }
    }
}

const StartRecognize = async (data: string) => {
    try {
        return await axios.post(createRoute(), createBody(data), createCfg());
    } catch (err) {
        return null;
    }
}

export {StartRecognize}

import timerStyle from "./timerStyle.module.css"
import {useEffect, useState} from "react";

type parameter = {
    visible?: boolean
    second: number
    active: boolean
    onEnd: () => void
}

const Timer = (prop: parameter) => {

    const [second, setSecond] = useState<number>(0)
    const [active, setActive] = useState<boolean>(false)
    const [onEnd, setOnEnd] = useState<boolean>(false)

    useEffect(() => {
        setSecond(prop.second)
    }, [prop.second])

    useEffect(() => {
        setActive(prop.active)
    }, [prop.active])

    useEffect(() => {
        if (onEnd) {
            setOnEnd(false)
            prop.onEnd()
            setActive(false)
        }
    }, [onEnd])

    useEffect(() => {
        if (active) {
            const interval = setInterval(() => {
                setSecond(second - 1)
                if (second === 1) {
                    console.log("timer on end!")
                    setOnEnd(true)
                }
            }, 1000);

            return () => clearInterval(interval);
        }

    }, [second, active])

    useEffect(() => {
        console.log("timer active", active)
    }, [active])

    const getSecondFormat = () => {
        if (second > 60 && second < 3599) {
            const hour = Math.floor(second/60)
            const sec = second%60 >= 10 ? second%60 : `0${second%60}`
            return `automatic continuation through - ${hour} min. ${sec} sec.`
        }

        if (second <= 60) {
            return `automatic continuation through - ${second} sec.`
        }
    }

    return (

        <div className={prop.visible ? timerStyle.timerVisible : timerStyle.timerInVisible}>{getSecondFormat()}</div>
    )
}

export {Timer}
